import { Dictionary, withCosmicModule } from '@finn/platform-modules';

import { ActionButton } from './ActionButton';
import { AddDriversModule } from './add-drivers-module';
import { Banners } from './Banners';
import { Button } from './button';
import { ChangePassword } from './change-password';
import { DataList } from './DataList';
import { Divider } from './Divider';
import { Documents } from './Documents';
import { DriversModule } from './drivers-module';
import { EditAccount, EditCompany } from './edit-account';
import { EmergencyCallBlock } from './EmergencyCallBlock';
import { FieldElement } from './field-element';
import { Fields } from './Fields';
import { InfoBlock } from './InfoBlock';
import { Invoices } from './Invoices';
import { InvoicesList } from './invoices-list';
import { Loyalty } from './Loyalty';
import { BackButton } from './navigation';
import { OrderStatus } from './OrderStatus';
import { PageInfoBlock } from './PageInfoBlock';
import { ProlongationTermsForm } from './ProlongationTermsForm';
import { Return } from './return';
import { ReturnInfo } from './ReturnInfo';
import { ReturnStatus } from './ReturnStatus';
import { Section } from './Section';
import { Services } from './Services';
import {
  ReportDamage,
  ReportDamageSummary,
  ScheduleService,
  ScheduleServiceSummary,
} from './services-forms';
import { SubscriptionActionModule } from './SubscriptionAction';
import { SubscriptionsList } from './SubscriptionsList';
import { TextBlock } from './TextBlock';
import { UserAccountData } from './UserAccountData';
import { VehicleDetailsBlock } from './VehicleDetailsBlock';

const featuresDictionary: Dictionary = new Dictionary();

export const getSelfServiceFeatures = () => featuresDictionary.getDictionary();

featuresDictionary.register(
  'subscriptions-list',
  withCosmicModule(SubscriptionsList)
);
featuresDictionary.register(
  'report-damage-button',
  withCosmicModule(ActionButton)
);
featuresDictionary.register(
  'schedule-service-button',
  withCosmicModule(ActionButton)
);
featuresDictionary.register('action-button', withCosmicModule(ActionButton));
featuresDictionary.register('faq-button', withCosmicModule(ActionButton));
featuresDictionary.register(
  'emergency-call-block',
  withCosmicModule(EmergencyCallBlock)
);
featuresDictionary.register('page-info-block', withCosmicModule(PageInfoBlock));
featuresDictionary.register(
  'vehicle-details-block',
  withCosmicModule(VehicleDetailsBlock)
);
featuresDictionary.register('return-status', withCosmicModule(ReturnStatus));
featuresDictionary.register('invoices-module', withCosmicModule(Invoices));
featuresDictionary.register(
  'services-module-deprecated',
  withCosmicModule(Services)
);
featuresDictionary.register('documents-module', withCosmicModule(Documents));
featuresDictionary.register('return-info', withCosmicModule(ReturnInfo));
featuresDictionary.register(
  'order-status-block',
  withCosmicModule(OrderStatus)
);

// TODO return-form deprecated, use return instead
featuresDictionary.register('return-form', withCosmicModule(Return));
featuresDictionary.register('return', withCosmicModule(Return));
featuresDictionary.register('field-element', withCosmicModule(FieldElement));

featuresDictionary.register(
  'prolongation-terms-form',
  withCosmicModule(ProlongationTermsForm)
);
featuresDictionary.register('data-list', withCosmicModule(DataList));
featuresDictionary.register('divider-module', withCosmicModule(Divider));
featuresDictionary.register('textblock', withCosmicModule(TextBlock));
featuresDictionary.register('banners', withCosmicModule(Banners));
featuresDictionary.register('fields', withCosmicModule(Fields));
featuresDictionary.register(
  'report-damage-form',
  withCosmicModule(ReportDamage)
);
featuresDictionary.register(
  'damage-summary',
  withCosmicModule(ReportDamageSummary)
);
featuresDictionary.register(
  'user-account-data',
  withCosmicModule(UserAccountData)
);
featuresDictionary.register('section', withCosmicModule(Section));
featuresDictionary.register(
  'change-password-form',
  withCosmicModule(ChangePassword)
);
featuresDictionary.register('edit-account-form', withCosmicModule(EditAccount));
featuresDictionary.register('drivers-module', withCosmicModule(DriversModule));
featuresDictionary.register(
  'add-drivers-module',
  withCosmicModule(AddDriversModule)
);
featuresDictionary.register('button', withCosmicModule(Button));
featuresDictionary.register('edit-company-form', withCosmicModule(EditCompany));
featuresDictionary.register(
  'schedule-service-form',
  withCosmicModule(ScheduleService)
);
featuresDictionary.register(
  'service-summary',
  withCosmicModule(ScheduleServiceSummary)
);
featuresDictionary.register('invoices-list', withCosmicModule(InvoicesList));
featuresDictionary.register('return-car-message', withCosmicModule(TextBlock));
featuresDictionary.register('loyalty-module', withCosmicModule(Loyalty));

featuresDictionary.register(
  'subscription-action',
  withCosmicModule(SubscriptionActionModule)
);

featuresDictionary.register('navigation-back', withCosmicModule(BackButton));
featuresDictionary.register('info-block', withCosmicModule(InfoBlock));
