import { Deal, useIntl } from '@finn/platform-modules';
import { SelfServiceRoutes } from '@finn/ua-constants';
import { ProductImage } from '@finn/ua-vehicle';
import { cn, IFinnSession, useCurrentLocale, useSession } from '@finn/ui-utils';
import Link from 'next/link';

import ActionItem from './ActionItem';

type SubscriptionItemProps = {
  deal: Deal;
  subscription_card_cta?: string;
};

const getCardLink = ({
  locale,
  deal,
  session,
}: {
  deal: Deal;
  session: IFinnSession;
  locale: string;
}) => {
  const helperUrl = `${session?.user?.hs_contact_id || deal?.contact_id}/${deal.id}/${deal.checkout_hash}?linksrc=action-item`;
  const fullCheckoutURL = `${window.location.origin}/${locale}/checkout/thank_you/${helperUrl}`;

  if (deal.variant === 'LEAD' && deal.checkout_hash) {
    return fullCheckoutURL;
  }

  if (
    deal?.state === 'CREATED' ||
    deal?.state === 'ACTIVE' ||
    deal?.state === 'INACTIVE' ||
    deal?.state === 'ENDED'
  ) {
    return `${SelfServiceRoutes.MY_CARS}/${deal?.id}`;
  }

  if (deal.checkout_hash) {
    return fullCheckoutURL;
  }

  return `${SelfServiceRoutes.MY_CARS}/${deal.id}`;
};

export const SubscriptionItem = ({
  deal,
  subscription_card_cta,
}: SubscriptionItemProps) => {
  const { formatMessage } = useIntl();
  const [session] = useSession();

  const isDealInactive = ['INACTIVE', 'ENDED'].includes(deal?.state);

  const licensePlate = deal?.car?.license_plate;
  const { locale } = useCurrentLocale();
  const cardLink = getCardLink({ locale, deal, session });

  return (
    <Link
      href={cardLink}
      prefetch
      className="bg-snow min-h-72 w-full cursor-pointer rounded"
      data-cy={`subscription-card-${deal?.state}`}
    >
      <ProductImage
        vehiclePictureURL={deal?.car?.picture}
        shouldPreload={false}
        shouldShowPlaceholder
        className={cn('top-0 pt-[193px] md:pt-[230px]', {
          'opacity-50': isDealInactive,
        })}
        imageClassName="!my-0 max-h-[193px] md:max-h-[230px]"
      />
      <div className="px-6 pb-6 pt-4 md:p-6">
        <ActionItem deal={deal} />
        <h4 className="body-16-semibold md:global-t5-semibold mt-3 md:mt-4">{`${deal?.car?.oem} ${deal?.car?.model}`}</h4>
        <p className="body-14-light mt-4 md:mt-6">
          {' '}
          {`${formatMessage('general.orderNumber')}: ${deal?.id}`}
          {licensePlate ? ` | ${deal.car?.license_plate}` : null}
        </p>
        <p className="body-14-semibold mt-4 underline">
          {subscription_card_cta}
        </p>
      </div>
    </Link>
  );
};
