import { FeatureFormField } from '@finn/b2c-cp/features-components/FeatureForm';
import { Grid } from '@finn/b2c-cp/features-components/Grid/Grid';
import { GridRow } from '@finn/b2c-cp/features-components/Grid/GridRow';
import { prepareWizardStep } from '@finn/b2c-cp/features-components/Wizard';
import { useCosmicModule, useDeal } from '@finn/platform-modules';
import { useIntl } from 'react-intl';

import { useDealId } from '../../../features-data';
import { TextBlock } from '../../TextBlock';

export const ReportStep = () => {
  const content = useCosmicModule('report-mileage');
  const { formatMessage } = useIntl();
  const dealId = useDealId();
  const deal = useDeal(dealId);

  const description = formatMessage(
    {
      id: 'report-mileage.description',
      defaultMessage: content?.report?.description,
    },
    {
      brand: deal.data?.car?.oem,
      model: deal.data?.car?.model,
    }
  );

  return (
    <div className="mt-8">
      <Grid cols={4}>
        <GridRow colsSpan={4}>
          <TextBlock text={description} />
          <br />
          <FeatureFormField
            type="number"
            name="current_mileage"
            label={content?.report?.mileage_input?.label}
          />
        </GridRow>
      </Grid>
    </div>
  );
};

export const reportStep = ({
  content,
}: {
  content?: {
    title?: string;
    footerCta?: string;
    validation?: {
      [key: string]: string | undefined;
    };
  };
}) => {
  return prepareWizardStep({
    render: () => <ReportStep />,
    title: content?.title,
    footer: { cta: { action: 'submit', label: content?.footerCta } },
    isCTADisabled: (errors) => Boolean(errors.current_mileage),
    fields: [
      {
        name: 'current_mileage',
        type: 'number',
        validation: {
          required: content.validation?.required,
          validInteger: {
            message: content.validation?.integer,
          },
        },
      },
    ],
  });
};
