import ModalContainer, {
  ModalProps,
} from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { SuccessModal } from '@finn/b2c-cp/features/reportMileage/steps/successModal';
import { ModalBuilder } from '@finn/b2c-cp/features-components/ModalBuilder';
import { useDealId } from '@finn/b2c-cp/features-data';
import { showToast } from '@finn/design-system/atoms/toast';
import { useCosmicModule } from '@finn/platform-modules';
import { trackEvent } from '@finn/ui-utils';
import axios from 'axios';
import { useCallback, useMemo, useState } from 'react';

import { reportStep } from './steps/report';

const MULTISTEP_REPORT_MILEAGE = 'mileage-report';

export const ReportMileageModal = (props: ModalProps) => {
  const content = useCosmicModule('report-mileage');
  const { handleSubmit } = useMileageFormSubmit({
    success_toast_title: content?.toast?.success?.title,
    success_toast_description: content?.toast?.success?.description,
    error_toast_title: content?.toast?.error?.title,
    error_toast_description: content?.toast?.error?.description,
  });

  const { openModal } = useModalStore();
  const [modalToShow, setModalToShow] = useState<'form' | 'success'>('form');

  const [mileageRecommendation, setMileageRecommendation] = useState<
    MileagePackageRecommendation | undefined
  >();

  const handleFormSubmit = useCallback(
    async (formData) => {
      const result = await handleSubmit({
        current_mileage: formData.current_mileage,
      });

      if (result) {
        trackEvent('Report-Mileage successful', {
          ...result,
          upgradeRecommendationShown:
            result.monthly_mileage_excess > 0 &&
            !!result?.recommended_mileage_package,
        });
        setMileageRecommendation(result);
        setModalToShow('success');
      } else {
        openModal('request-error-modal');
      }
    },
    [handleSubmit, openModal]
  );

  const steps = useMemo(
    () => [
      {
        ...reportStep({
          content: {
            title: content?.report?.title,
            footerCta: content?.report?.cta,
            validation: {
              required: content?.report?.mileage_input?.validateRequired,
              integer: content?.report?.mileage_input?.validateInteger,
            },
          },
        }),
        id: 'reportStep',
        slug: 'report',
      },
    ],
    [content]
  );

  if (modalToShow === 'success') {
    return (
      <SuccessModal
        open={props.open}
        onClose={props.onClose}
        data={mileageRecommendation}
      />
    );
  }

  if (modalToShow === 'form') {
    return (
      <ModalBuilder
        location="report-mileage"
        key={modalToShow}
        open={props.open}
        onFormSubmit={handleFormSubmit}
        onClose={props.onClose}
        steps={steps}
      />
    );
  }
};

const renderNewReportMileageModal = (open: boolean, onClose: () => void) => {
  return <ReportMileageModal open={open} onClose={onClose} />;
};

export const ReportMileageModalContainer = () => {
  const dealId = useDealId();

  if (!dealId) {
    return null;
  }

  return (
    <ModalContainer
      name={MULTISTEP_REPORT_MILEAGE}
      renderModal={renderNewReportMileageModal}
    />
  );
};

type UseFormSubmit = {
  success_toast_title?: string;
  success_toast_description?: string;
  error_toast_title?: string;
  error_toast_description?: string;
};

interface MileagePackage {
  mileage_package?: number;
  mileage_package_fee?: number;
}

export interface MileagePackageRecommendation {
  monthly_mileage_usage: number;
  monthly_mileage_excess: number;
  estimated_total_mileage_usage: number;
  estimated_total_excess: number;
  estimated_total_excess_mileage_cost: number;
  recommended_mileage_package: MileagePackage | null;
}

export interface MileagePackageRecommendationResponse {
  data: MileagePackageRecommendation;
}

export const useMileageFormSubmit = ({
  success_toast_title,
  success_toast_description,
  error_toast_title,
  error_toast_description,
}: UseFormSubmit) => {
  const dealId = useDealId();

  const handleSubmit = useCallback(
    async (values: {
      current_mileage: number;
    }): Promise<MileagePackageRecommendation | undefined> => {
      try {
        const { data } = await axios.post<MileagePackageRecommendationResponse>(
          `/portal/api/bff/deals/${dealId}/mileage-report`,
          values
        );

        showToast({
          title: success_toast_title,
          description: success_toast_description,
          status: 'success',
        });

        return data.data;
      } catch (error) {
        showToast({
          title: error_toast_title,
          description: error_toast_description,
          status: 'error',
        });

        return undefined;
      }
    },
    [
      dealId,
      success_toast_title,
      success_toast_description,
      error_toast_title,
      error_toast_description,
    ]
  );

  return { handleSubmit };
};
