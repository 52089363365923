import { useServiceBookingPortal } from '@finn/b2c-cp/features-data';
import { AddTime } from '@finn/design-system/icons/add-time';
import { CarRepairOutlined } from '@finn/design-system/icons/car-repair-outlined';
import { CarReturnOutlined } from '@finn/design-system/icons/car-return-outlined';
import { CurrencyEuro } from '@finn/design-system/icons/currency-euro';
import { HelpOutlined } from '@finn/design-system/icons/help-outlined';
import { SwapHoriz } from '@finn/design-system/icons/swap-horiz';
import { WrenchOutlined } from '@finn/design-system/icons/wrench-outlined';
import { Action, VisibilityConfig } from '@finn/platform-modules';
import { cn } from '@finn/ui-utils';
import Link from 'next/link';
import { Fragment, useMemo } from 'react';

import {
  useDealId,
  useFeatureAction,
  useFeatureTracking,
  useIsFeatureVisible,
} from '../features-data';

const iconsMap = {
  'report-damage-button': WrenchOutlined,
  'schedule-service-button': CarRepairOutlined,
  'faq-button': HelpOutlined,
  'car-swap': SwapHoriz,
  'car-return': CarReturnOutlined,
  'more-time': AddTime,
  'filled-currency-eur': CurrencyEuro,
};

export type ActionButtonProps = {
  slug: string;
  title: string;
  iconName?: string;
  description?: string;
  url?: string;
  fallbackUrl?: string;
  exceptionId?: string;
  action?: Action;
  modalName?: string;
  visibilityConfig?: VisibilityConfig;
  trackingProps?: Record<string, string | { field: string }>;
  disabled?: VisibilityConfig;
};

// TODO design-system: refactor !important styles after migration to new typography APP-1443

export const ActionButton = ({
  title,
  description,
  iconName,
  action,
  modalName,
  visibilityConfig,
  url,
  fallbackUrl,
  slug,
  trackingProps,
  disabled: disabledConfig,
}: ActionButtonProps) => {
  const dealId = useDealId();
  const Icon = iconsMap[iconName || slug] || Fragment;
  const runAction = useFeatureAction({
    action,
    modalName,
    url,
    trackingProps,
  });
  const isVisible = useIsFeatureVisible(visibilityConfig);
  const isDisabled = useIsFeatureVisible(disabledConfig, false);

  const { data } = useServiceBookingPortal(dealId);

  // TODO refactor after we start to support remote data fetching via cosmic
  const urlWithParams = useMemo(() => {
    const utm_source = dealId ? 'my_car_details' : '';
    const formattedUrl = url
      ?.replace('{id}', dealId)
      ?.replace('{id}', dealId)
      ?.replace('{utm_source}', utm_source);

    if (url === '{external_schedule_url}') {
      return (
        data?.url ||
        fallbackUrl
          ?.replace('{id}', dealId)
          ?.replace('{id}', dealId)
          .replace('{utm_source}', utm_source)
      );
    }

    return formattedUrl;
  }, [data?.url, url, fallbackUrl, dealId]);

  useFeatureTracking({ isVisible, action, modalName, url });

  if (!isVisible) {
    return null;
  }

  const Wrapper = urlWithParams ? Link : Fragment;
  const props = urlWithParams ? { href: urlWithParams } : {};
  // TODO remove it before going live
  const extra = { legacyBehavior: true };

  return (
    <Wrapper {...props} {...extra}>
      <a
        className={cn({
          'border-pewter grid cursor-pointer grid-cols-[min-content_1fr_min-content] rounded border p-4 hover:border-black sm:px-4 sm:py-6':
            true,
          'pointer-events-none cursor-default opacity-50': isDisabled,
        })}
        data-cy="click-to-action"
        onClick={urlWithParams ? undefined : runAction}
      >
        <Icon className="!h-6 !w-6" />
        <div className="ml-4 flex flex-col justify-center gap-y-2">
          <h6 className="global-t6-semibold">{title}</h6>
          {description ? (
            <p className="body-14-regular">{description}</p>
          ) : null}
        </div>
      </a>
    </Wrapper>
  );
};
