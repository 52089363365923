/* Attached to https://app-v1.cosmicjs.com/finnauto/edit-object/65e758cebfc8e36aca7945ab */
import { useModalStore } from '@finn/b2c-cp/deprecated/modules/modals/store';
import { transformVehicleDetailsData } from '@finn/b2c-cp/features-data/helpers';
import { useRedirectToAuthIfNotLoggedIn } from '@finn/b2c-cp/hooks/useRedirectToAuthIfNotLoggedIn';
import { Button } from '@finn/design-system/atoms/button';
import { SettingsFilled } from '@finn/design-system/icons/settings-filled';
import { SupportAgent } from '@finn/design-system/icons/support-agent';
import {
  DataModule,
  DataModuleBody,
  DataModuleFooter,
  DataModuleHeader,
  DataModuleTitle,
} from '@finn/design-system/modules/DataModule';
import {
  Action,
  useCarSaleInfo,
  useCurrentHandoverDateCKO,
  useDeal,
  VisibilityConfig,
} from '@finn/platform-modules';
import get from 'lodash/get';
import { useEffect } from 'react';

import { DataRowItem, DSRow } from '../../features-components/DataRow';
import {
  useDealId,
  useFeatureAction,
  useFeatureTracking,
  useIsFeatureVisible,
  useWarmUpDealData,
} from '../../features-data';

export type VehicleDetailsBlockProps = {
  vehicle_status: { [key: string]: string };
  data_rows: { data: DataRowItem }[];
  footer: ServiceItem[];
};

const iconsMap = {
  support: SupportAgent,
  settings: SettingsFilled,
};
type Icon = 'support' | 'settings';

type ServiceItem = {
  config: {
    devonly?: boolean;
    label: string;
    icon?: Icon;
    action: Action;
    modalName?: string;
    visibilityConfig?: VisibilityConfig;
  };
};

const Service = ({ service }: { service: ServiceItem }) => {
  const runAction = useFeatureAction(service.config);
  const isVisible = useIsFeatureVisible(service.config.visibilityConfig);

  useFeatureTracking({ isVisible, ...service.config });

  if (!isVisible) {
    return null;
  }

  const Icon = iconsMap[service?.config?.icon];

  return (
    <Button variant="outline" className="w-full md:w-auto" onClick={runAction}>
      {Icon ? <Icon className="mr-1" /> : null}
      {service?.config?.label}
    </Button>
  );
};

export const VehicleDetailsBlock = ({
  // TODO: remove from cosmic after merge
  // banners,
  data_rows,
  footer,
}: VehicleDetailsBlockProps) => {
  useWarmUpDealData();
  const { openModal } = useModalStore();
  const dealId = useDealId();
  const { data: rawData, status } = useDeal(dealId, (httpStatus) => {
    if (httpStatus !== 404) {
      openModal('error-modal');
    }
  });
  const handoverDateCKO = useCurrentHandoverDateCKO(dealId);

  const data = transformVehicleDetailsData({
    data: rawData,
    handoverDateCKO,
  });
  useCarSaleInfo(data?.car_id); // just to prefetch+cache

  // small backdoor for testing purposes
  // to be able to open modals from the console
  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.openSelfServiceModal = openModal;
  }, [openModal]);

  const showSkeleton = status === 'loading' || !data;

  useRedirectToAuthIfNotLoggedIn();

  return (
    <div className="container mx-0 grid auto-rows-min gap-6">
      <DataModule>
        <DataModuleHeader>
          <DataModuleTitle>
            {/* {title} */}
            {/* TODO: after merge change this title in cosmic and remove hardcode */}
            Vertragsdetails
          </DataModuleTitle>
        </DataModuleHeader>
        <div className="flex flex-col-reverse gap-4 md:flex-row md:items-center">
          <DataModuleBody className="w-full">
            {data_rows.map((row) => (
              <DSRow
                key={row?.data?.key}
                status={showSkeleton ? 'loading' : status}
                config={row?.data}
                value={get(
                  data,
                  row?.data?.key?.replace('.registration_data', '')
                )}
                tooltipValue={get(data, row?.data?.tooltip?.key)}
              />
            ))}
          </DataModuleBody>
        </div>
        <DataModuleFooter>
          {footer?.map((service) => (
            <Service key={service.config.label} service={service} />
          ))}
        </DataModuleFooter>
      </DataModule>
    </div>
  );
};
