import { useDealId } from '@finn/b2c-cp/features-data';
import { InsuranceUpgradeModal, useCosmicModule } from '@finn/platform-modules';
import { ModalContainer, ModalKey } from '@finn/ua-modals';

// this is for LEAD variant, for SUBSCRIPTION variant we have another modal defined in cosmic
export const InsuranceUpgradeModalContainerLead = () => {
  const insuranceUpgradeModule = useCosmicModule('insurance-upgrade');
  const insuranceUpgradeModalData = insuranceUpgradeModule?.data?.modal || {};

  const dealId = useDealId();

  if (!dealId) {
    return null;
  }

  return (
    <ModalContainer
      modalKey={ModalKey.INSURANCE_UPGRADE}
      dealId={Number(dealId)}
      modalData={insuranceUpgradeModalData}
      ModalComponent={InsuranceUpgradeModal}
      refreshOnSuccess
    />
  );
};
