import { ClockOutlined } from '@finn/design-system/icons/clock-outlined';
import { ErrorFilled } from '@finn/design-system/icons/error-filled';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { WarningFilled } from '@finn/design-system/icons/warning-filled';
import { VisibilityConfig } from '@finn/platform-modules';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { cn, parseToHtml } from '@finn/ui-utils';
import { ReactNode, useEffect } from 'react';

import { FormatConfig, useIsFeatureVisible, useValue } from '../features-data';

export type InfoBlockProps = {
  iconType?: string;
  description?: string | ReactNode;
  formatConfig?: FormatConfig;
  className?: string;
  location?: string;
  visibilityConfig?: VisibilityConfig;
};

const getIcon = (iconType: string) => {
  switch (iconType) {
    case 'warning':
      return <WarningFilled className="h-6 w-6" />;
    case 'info':
      return <InfoOutlined className="h-6 w-6" />;
    case 'error':
      return <ErrorFilled className="h-6 w-6" />;
    case 'clock':
      return <ClockOutlined className="h-6 w-6" />;
    default:
      return null;
  }
};

export const InfoBlock = ({
  iconType,
  description,
  formatConfig,
  className,
  visibilityConfig,
  location,
}: InfoBlockProps) => {
  const [formatedText] = useValue(
    typeof description === 'string' ? description : '',
    formatConfig
  );
  const track = useTrackingStore((state) => state.track);

  const isVisible = useIsFeatureVisible(visibilityConfig);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    track(TrackingEventName.COMPONENT_VIEWED, {
      location: `InfoBlock: ${location}`,
    });
  }, [track, isVisible, location]);

  const content =
    typeof description === 'string' ? parseToHtml(formatedText) : description;

  if (!isVisible) {
    return null;
  }

  return (
    <div className="bg-snow flex items-center gap-x-2 gap-y-5 rounded p-4">
      <span className="shrink-0">{iconType && getIcon(iconType)}</span>
      <p
        className={cn(
          'body-14-regular before:hidden after:hidden',
          '[&_ul]:ps-10 [&_ul_li]:list-[circle]',
          className
        )}
      >
        {content}
      </p>
    </div>
  );
};
